<template>
    <b-card border-variant="dark">
        <b-card-title style="font-size: x-large;"> Order Volume </b-card-title>
      <b-col cols="4">

        <b-button variant="primary" :to="{ name: 'commerce' }" size="sm">
          <feather-icon size="1x" icon="ArrowLeftIcon" /> Back to Dashboard
        </b-button>
      </b-col>
      <div id="chart">
        <apexchart type="line" height="350" :options="quickStats.chartOptions" :series="quickStats.series"></apexchart> 
      </div>
      <br />
    </b-card>
  </template>
  
  <script>
  import { dateFormat } from '@/utils/utils';
import { timingSafeEqual } from 'crypto';
  import { between } from 'vee-validate/dist/rules';
  import VueApexCharts from 'vue-apexcharts'

  export default {
    components: {
      apexchart: VueApexCharts,
    },
    data() {
      return {
        form: {
        },
      };
    },
  
    methods: {
      clearForm (){
        this.form = {}
      },
      
    },
  
    created() {
      document.title = "Chart | Tai Sin";
    },
    computed: {
      quickStats() {
        let getData = this.$store.getters["dashboard/getQuickStats"]
        return getData
      }

    },
  
    mounted() {
      this.$store.dispatch("dashboard/fetchQuickStats")
    },
  };
  </script>
  
  <style scoped>
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button {  
      -webkit-appearance: textfield;
      appearance: textfield;
  }</style>